import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4821ccc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-message" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "error-message"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_MetricsViewer = _resolveComponent("MetricsViewer")!
  const _component_BreakdownComponent = _resolveComponent("BreakdownComponent")!
  const _component_CopilotChatViewer = _resolveComponent("CopilotChatViewer")!
  const _component_SeatsAnalysisViewer = _resolveComponent("SeatsAnalysisViewer")!
  const _component_ApiResponse = _resolveComponent("ApiResponse")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, {
        color: "indigo",
        elevation: "4"
      }, {
        extension: _withCtx(() => [
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
            "align-tabs": "title"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabItems, (item) => {
                return (_openBlock(), _createBlock(_component_v_tab, {
                  key: item,
                  value: item
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_btn, { icon: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-github")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_toolbar_title, { class: "toolbar-title" }, {
            default: _withCtx(() => [
              _createTextVNode("Copilot Metrics Viewer | " + _toDisplayString(_ctx.capitalizedItemName) + " : " + _toDisplayString(_ctx.displayedViewName), 1)
            ]),
            _: 1
          }),
          _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.mockedDataMessage), 1),
          _createVNode(_component_v_spacer)
        ]),
        _: 1
      }),
      (_ctx.apiError)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "error-message",
            innerHTML: _ctx.apiError
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.apiError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.itemName === 'invalid')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Invalid Scope in .env file. Please check the value of VUE_APP_SCOPE."))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (!_ctx.metricsReady)
                    ? (_openBlock(), _createBlock(_component_v_progress_linear, {
                        key: 0,
                        indeterminate: "",
                        color: "indigo"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.metricsReady)
                    ? (_openBlock(), _createBlock(_component_v_window, {
                        key: 1,
                        modelValue: _ctx.tab,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabItems, (item) => {
                            return (_openBlock(), _createBlock(_component_v_window_item, {
                              key: item,
                              value: item
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, { flat: "" }, {
                                  default: _withCtx(() => [
                                    (item === _ctx.itemName)
                                      ? (_openBlock(), _createBlock(_component_MetricsViewer, {
                                          key: 0,
                                          metrics: _ctx.metrics
                                        }, null, 8, ["metrics"]))
                                      : _createCommentVNode("", true),
                                    (item === 'languages')
                                      ? (_openBlock(), _createBlock(_component_BreakdownComponent, {
                                          key: 1,
                                          metrics: _ctx.metrics,
                                          breakdownKey: 'language'
                                        }, null, 8, ["metrics"]))
                                      : _createCommentVNode("", true),
                                    (item === 'editors')
                                      ? (_openBlock(), _createBlock(_component_BreakdownComponent, {
                                          key: 2,
                                          metrics: _ctx.metrics,
                                          breakdownKey: 'editor'
                                        }, null, 8, ["metrics"]))
                                      : _createCommentVNode("", true),
                                    (item === 'copilot chat')
                                      ? (_openBlock(), _createBlock(_component_CopilotChatViewer, {
                                          key: 3,
                                          metrics: _ctx.metrics
                                        }, null, 8, ["metrics"]))
                                      : _createCommentVNode("", true),
                                    (item === 'seat analysis')
                                      ? (_openBlock(), _createBlock(_component_SeatsAnalysisViewer, {
                                          key: 4,
                                          seats: _ctx.seats
                                        }, null, 8, ["seats"]))
                                      : _createCommentVNode("", true),
                                    (item === 'api response')
                                      ? (_openBlock(), _createBlock(_component_ApiResponse, {
                                          key: 5,
                                          metrics: _ctx.metrics,
                                          seats: _ctx.seats
                                        }, null, 8, ["metrics", "seats"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}